import axios from "axios"
import _ from 'lodash'
import { GET_PERMISSIONS } from "constants/ApiConstant"

class Permission {

  permissions = undefined

  constructor () {

  }

  async setPermissions() {
    try {
      if (!this.permissions) {
        const { data } = await axios.get(GET_PERMISSIONS())

        this.permissions = data.data
      }

      return this.permissions

    } catch (error) {
      console.log(error)
    }
  }

  hasAccess(controller = '', action = '') {
    if (!this.permissions) return false

    return _.find(this.permissions, { controller, action })
  }
}

export default new Permission()