/* eslint-disable no-unused-expressions */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
export const numberWithCommas = (x) =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const numberRialToTomanWithCommas = (x) =>
  (x / 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const numberToDelNumber = (x) =>
  x.toString().replace(/\d/g, (digit) => `${digit}\u0336`);

export const isNumeric = (x) => !isNaN(parseFloat(x)) && isFinite(x); //eslint-disable-line

export const toPersianNum = (n) => {
  const farsiDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

  return n
    .toString()
    .split('')
    .map((x) => farsiDigits[x])
    .join('');
};

export const transformKeyLabel = (data) => {
  return data
    ? data.map((item) => {
        const option = {};
        if (item) {
          // option.value = specialty.id ? specialty.id : 0;
          option.value = item.name ? item.name : 0;
          option.label = item.name ? item.name : '';
        }
        return option;
      })
    : [];
};

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const objToQuery = (obj) =>
  Object.keys(obj)
    .reduce((a, k) => {
      a.push(`${k}=${encodeURIComponent(obj[k])}`);
      return a;
    }, [])
    .join('&');

export const serialize = (obj) => {
  const str = [];
  for (const p in obj) {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
  }
  return str.join('&');
};

export const serializeArray = (obj) => {
  const str = [];
  for (const p in obj) {
    const k = p;
    const v = obj[p];
    if (typeof v === 'object') {
      v &&
        v.length > 0 &&
        v.map((item) =>
          str.push(`${encodeURIComponent(k)}_in=${encodeURIComponent(item)}`)
        );
    } else {
      str.push(`${encodeURIComponent(k)}=${v ? encodeURIComponent(v) : ''}`);
    }
  }
  return str.join('&');
};

export const queryBuilder = (obj) => {
  const ret = {};
  const query = obj;
  delete query.search;
  delete query.sort;
  Object.keys(query).forEach((q) => {
    if (q !== '' && query[q].split('__')[1] !== '')
      ret[`${query[q].split('__')[0]}__${q}`] = query[q].split('__')[1];
  });
  return serialize(ret);
};

export const queryToObject = (query) => {
  const ret = {};
  Object.keys(query).forEach((q) => {
    if (q !== '' && query[q].split('__')[1] !== '')
      ret[q.split('__')[1]] = `${q.split('__')[0]}__${query[q]}`;
  });
  return ret;
};

/**
 * Get the user IP throught the webkitRTCPeerConnection
 * @param onNewIP {Function} listener function to expose the IP locally
 * @return undefined
 */
export const getUserIP = (onNewIP) => {
  //  onNewIp - your listener function for new IPs
  // compatibility for firefox and chrome
  const MyPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection;
  const pc = new MyPeerConnection({
    iceServers: [],
  });
  const noop = () => {};
  const localIPs = {};
  const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;

  function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  }

  // create a bogus data channel
  pc.createDataChannel('');

  // create offer and set local description
  pc.createOffer()
    .then((sdp) => {
      sdp.sdp.split('\n').forEach((line) => {
        if (line.indexOf('candidate') < 0) return;
        line.match(ipRegex).forEach(iterateIP);
      });

      pc.setLocalDescription(sdp, noop, noop);
    })
    .catch(() => {
      // An error occurred, so handle the failure to connect
    });

  // listen for candidate events
  pc.onicecandidate = (ice) => {
    if (
      !ice ||
      !ice.candidate ||
      !ice.candidate.candidate ||
      !ice.candidate.candidate.match(ipRegex)
    )
      return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};
