import { createContext, useState, useEffect } from 'react';
import { setCookie, destroyCookie } from 'nookies';
import axios from 'axios';
import { PAYMENT_METHOD_GET_API, USER_GET_API } from 'constants/ApiConstant';
import { REGISTER_TOKEN, AUTH_TOKEN, CAPTCHA_TOKEN } from 'redux/constants/Auth';

export const appContext = createContext({
  isAuthenticated: false,
  setAuthenticated: () => {},
  token: null,
  setToken: () => {},
  userID: null,
  setUserID: () => {},
  user: null,
  setUser: () => {},
  paymentMethods: null,
  setPaymentMethods: () => {},
  role: null,
  setRole: () => {},
  // collapse: false,
  // setCollapse: () => {},
  // notifications: {},
  // setNotifications: () => {},
});

export const AppProvider = (props) => {
  const [isAuthenticated, setAuthenticated] = useState(props.authenticated);
  const [token, setToken] = useState(props.token);
  const [userID, setUserID] = useState(props.userID);
  const [user, setUser] = useState(props.user);
  const [paymentMethods, setPaymentMethods] = useState(props.paymentMethods);
  const [role, setRole] = useState(props.role);


  // useEffect(() => {
  //   const oldAuthenticate = localStorage.getItem(AUTH_TOKEN) ? true : false;
  //   const oldToken = localStorage.getItem(AUTH_TOKEN);
  //   if (!isAuthenticated || !localStorage.getItem(AUTH_TOKEN)) {
  //     // destroyCookie(null, 'token', {
  //     //   path: '/',
  //     // });
  //     localStorage.removeItem(REGISTER_TOKEN);
  //     localStorage.removeItem(AUTH_TOKEN);
  //     setUserID(null);
  //     setToken(null);
  //     setUser(null);
  //     setRole(null);
  //     setPaymentMethods(null);
  //     // setNotifications({});
  //     axios.defaults.headers.common.Authorization = ``;
  //   } else {
  //       setAuthenticated(props.authenticated);
  //       setToken(props.token);
  //       setUserID(props.userID);
  //       setUser(props.user);
  //       setPaymentMethods(props.paymentMethods);
  //       setRole(props.role);
  //   }
  // }, [isAuthenticated]);

  // const [collapse, setCollapse] = useState(props.collapse);
  // const [notifications, setNotifications] = useState(props.notifications);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + token;
      // axios.defaults.headers.common['Authorization'] = token;
      // axios.interceptors.request.use(function (config) {
      //   config.headers.Authorization =  token;
      //   return config;
      // });
      const profileMeUrl = USER_GET_API('me');
      fetch(profileMeUrl, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      }).then((profileResponse) => {
        profileResponse.json().then((json) => {
          console.log("USER IS THIS: ");
          console.log(json);
          if(json?.message === "Invalid token.") {
              localStorage.removeItem(AUTH_TOKEN);
              localStorage.removeItem(REGISTER_TOKEN);
              localStorage.removeItem(CAPTCHA_TOKEN);
              window.location.reload();
          }
          setUser(json);
          setRole(json?.role?.type);
          setUserID(json?.id);
        });
      }).catch((error) => {
        console.log("GOT AN ERROR FOR USER:");
        console.log(error);
        console.log(error.response);
      });

      const paymentMethodsUrl = PAYMENT_METHOD_GET_API();
      fetch(paymentMethodsUrl, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      }).then((paymentMethodResponse) => {
        paymentMethodResponse.json().then((json) => {
          setPaymentMethods(json);
        });
      });
    } else {
      setUser(null);
      setUserID(null);
      setRole(null);
      setPaymentMethods(null);
      axios.defaults.headers.common.Authorization = '';
    }
  }, [token]);

  // useEffect(() => {
  //   const oldAuthenticate = isAuthenticated;
  //   const oldToken = token;
  //   if (!isAuthenticated || !token) {
  //     // destroyCookie(null, 'token', {
  //     //   path: '/',
  //     // });
  //     localStorage.removeItem(REGISTER_TOKEN);
  //     localStorage.removeItem(AUTH_TOKEN);
  //     setUserID(null);
  //     setToken(null);
  //     setUser(null);
  //     setRole(null);
  //     setNotifications({});
  //     axios.defaults.headers.common.Authorization = ``;
  //   }
  // }, [isAuthenticated]);

  // useEffect(() => {
  //   if (!collapse) {
  //     setCookie(null, 'collapse', false, {
  //       maxAge: 30 * 24 * 60 * 60,
  //       path: '/',
  //     });
  //   } else {
  //     setCookie(null, 'collapse', true, {
  //       maxAge: 30 * 24 * 60 * 60,
  //       path: '/',
  //     });
  //   }
  // }, [collapse]);

  return (
    <appContext.Provider
      value={{
        isAuthenticated,
        setAuthenticated,
        token,
        setToken,
        userID,
        setUserID,
        user,
        setUser,
        paymentMethods,
        setPaymentMethods,
        role,
        setRole,
        // collapse,
        // setCollapse,
        // notifications,
        // setNotifications,
      }}
    >
      {props.children}
    </appContext.Provider>
  );
};
