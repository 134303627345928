import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import AppLayout from 'layouts/app-layout'
import AuthLayout from 'layouts/auth-layout'
import AppLocale from 'lang'
import { IntlProvider } from 'react-intl'
import { ConfigProvider } from 'antd'
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass'
import permission from 'utils/permission'

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

const flattenMessages = (nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {}
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value })
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    console.log(messages)
    return messages
  }, {})
}

export const Views = (props) => {
  permission.setPermissions()
  const { token, location } = props

  const _appLocale = AppLocale[useSelector(({ theme }) => theme.locale)]

  // const [_appLocale, setAppLocale] = useState({
  //   locale: currentAppLocale.locale,
  //   direction: currentAppLocale.direction,
  //   messages: currentAppLocale.messages,
  // })

  // useEffect(() => {
  //   setAppLocale({
  //     locale: currentAppLocale.locale,
  //     direction: currentAppLocale.direction,
  //     messages: currentAppLocale.messages,
  //   })
  // }, [currentAppLocale])

  useBodyClass(`dir-${_appLocale.direction}`)
  return (
    <IntlProvider
      locale={_appLocale.locale}
      messages={flattenMessages(_appLocale.messages)}
    >
      <ConfigProvider
        locale={_appLocale.locale}
        direction={_appLocale.direction}
      >
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH} />
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={_appLocale.direction} />
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={_appLocale.direction} location={location} />
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme
  const { token } = auth
  return { locale, direction, token }
}

export default withRouter(connect(mapStateToProps)(Views))
