import axios from "axios";
import {
  GRAPHQL,
  LOGIN_POST_API,
  ADMIN_LOGIN_POST_API,
  REGISTER_POST_API,
  FORGOT_PASSWORD_POST_API,
  RESET_PASSSWORD_POST_API,
  CHANGE_USER_PASSWORD_POST_API,
  EMAIL_CONFIRMATION_GET_API,
  SEND_EMAIL_CONFIRMATION_POST_API,
  SMS_POST_API,
  SMS_VERIFY_POST_API,
  SMS_VERIFY_GET_API,
  SMS_CHANGE_NUMBER_API,
  SMS_RESEND_CODE_API,
  PROFILE_PUT_API,
  USERS_GET_API,
  USERS_COUNT_GET_API,
  USERS_POST_API,
  UPLOAD_POST_API,
  COMMENTS_GET_API,
  IDENTITY_APPROVE_CODE_POST_API,
  SECURITY_PIN_GET_API,
  USER_ME_GET_API,
  WALLET_NUMBER_POST_API,
  WALLET_TRANSFER_POST_API,
  SUM_OF_TRANSACTION_GET_API,
  TRANSACTIONS_GET_API,
  TRANSACTIONS_GET_ALL_API,
  ACTIVE_USERS_GET_API,
  COMMISSION_CALCULATE_GET_API,
  TRANSACTIONS_POST_API,
  NOTIFICATION_GET_API,
  PAYMENT_METHOD_POST_API,
  PAYMENT_METHOD_GET_API,
  PAYMENT_METHOD_DELETE_API,
  SYSTEM_PAYMENT_METHOD_GET_API,
  SYNC_USER_ACCOUNTS_GET_API,
  ACCOUNT_DEPOSIT_POST_API,
  ACCOUNT_WITHDRAWAL_POST_API,
  GET_PHONE_TRADING_GET_API,
  SET_PHONE_TRADING_POST_API,
  UPDATE_PHONE_TRADING_GET_API,
  TWO_FACTOR_ENABLE_GET_API,
  TWO_FACTOR_VERIFY_POST_API,
  TWO_FACTOR_DISABLE_GET_API,
  TWO_FACTOR_LOGIN_POST_API,
  CUSTOME_COMMENTS_POST,
  EXCHNAGE_GET_API,
  TRANSACTIONS_APPROVE_POST_API,
  USER_GET_API,
  AFFILIATE_COMMISSION_GET_API,
  FINANCIAL_STATISTICS_GET_API,
  USER_AVATAR_API,
  USER_SEND_DOCUMENTS,
  USER_GET_DOCUMENTS,
  USER_DOCUMENTS_ACTION,
  FUNDING_METHOD_SETTING_GET_API,
  PAYMENT_METHOD_UPDATE_API,
  UPDATE_USER_NOTES,
  ADD_USER_NOTES,
  GET_USER_NOTES,
  GET_USER_OPEN_POSITIONS,
  GET_USER_DEALS_HISTORY,
  GET_FAQS,
  USER_MAIL_API,
  USER_GET_MAIL_API,
  GET_MAIL_BY_ID_API,
  GET_MAIL_LABELS_API,
  GET_MAIL_LABELS_DATA_API,
  MAIL_PUT_MULTIPLE,
  GET_ACCOUNT_DATA,
  ACCOUNTS_TYPE_GET_API,
  CASH_BACK_AFFILIATE_GET_API,
  IB_GET_API,
  PAYOUTS_HISTORY_GET_API,
  ACCOUNTS_POST_API,
  GET_CALENDAR_API,
  DELETE_CALENDAR_API,
  POST_CALENDAR_API,
  ADVERTISING_MEDIA_GET_API,
  CHART_GET_API,
  WALLETS_GET_API,
  IB_USERS_GET_API,
  EMAIL_SEND_VERIFICATION,
  GET_USER_STATUS,
  UPDATE_USER_STATUS,
  GET_ALL_SUPPORTERS,
  ASSIGN_SUPPORT_API,
  GET_LEVERAGE_API,
  UPDATE_USER_LEVERAGE_API,
  UPDATE_USER_DEPOSIT_API,
  RESET_ACCOUNT_PASSWORD,
  CHANGE_ACCOUNT_PASSWORD,
  ACCOUNT_GET_API,
  CONFIRM_USER_MAIL,
  CONFIRM_USER_PHONE,
  COUNT_USERS,
  GET_CASHBACK_API,
  TRANSACTIONS_PUT_API,
  SETTING_GET_API,
  TOTAL_CASHBACK_API,
  TOTAL_COMMISSION_GET_API,
  WALLET_HISTORY_GET_API,
  TOTAL_LOT_GET_API,
  ALL_COMMISSION_GET_API,
  PERMISSIONS_GET_API,
  ROLES_GET_API,
  ROLES_POST_API,
  ROLES_PUT_API,
  ROLE_PERMISSIONS_GET_API,
  PERMISSIONS_PUT_API,
  CHECK_DOCUMENT_VERIFICATION,
  EMAIL_MANUAL_SEND_VERIFICATION,
  PHONE_MANUAL_SEND_VERIFICATION
} from "constants/ApiConstant";
import { AUTH_TOKEN } from 'redux/constants/Auth';
import { serialize, serializeArray } from "../modules";
import { isEmptyArray } from "formik";

export const checkDocsVerification = (id) => {
  const url = CHECK_DOCUMENT_VERIFICATION();
  return axios.get(url, { params: { user: id, check: true } });
};

export const loginPost = (data, captcha) => {
  const url = LOGIN_POST_API();
  return axios.post(url, { captcha, ...data });
};

export const adminLoginPost = (data, captcha) => {
  const url = ADMIN_LOGIN_POST_API();
  return axios.post(url, { captcha, ...data });
};

export const registerPost = (data) => {
  const url = REGISTER_POST_API();
  return axios.post(url, data);
};

export const forgotPasswordPost = (data) => {
  const url = FORGOT_PASSWORD_POST_API();
  return axios.post(url, data);
};

export const resetPasswordPost = (data) => {
  const url = RESET_PASSSWORD_POST_API();
  return axios.post(url, data);
};

export const changeUserPassword = (data) => {
  const url = CHANGE_USER_PASSWORD_POST_API();
  return axios.post(url, data);
};

export const loginSmsPost = (data) => {
  const url = SMS_POST_API();
  return axios.post(url, data);
};

export const accountPost = (data) => {
  const url = ACCOUNTS_POST_API();
  return axios.post(url, data);
};

export const smsChangeNumber = (data) => {
  const url = SMS_CHANGE_NUMBER_API();
  return axios.post(url, data);
};

export const smsResendCode = (data) => {
  console.log("INFO:");
  console.log(data);
  const url = SMS_RESEND_CODE_API();
  return axios.post(url, data);
};

export const emailConfirmationPost = (data) => {
  const url = SEND_EMAIL_CONFIRMATION_POST_API();
  return axios.post(url, data);
};

export const sendEmailVerificationToUser = (data) => {
  const url = EMAIL_SEND_VERIFICATION();
  return axios.post(url, data);
};

export const checkEmailConfirmationPost = (data) => {
  const url = EMAIL_CONFIRMATION_GET_API();
  return axios.post(url, data);
};

export const loginSmsVerifyPost = (data, userId = null) => {
  console.log("VERIFY INFO:");
  console.log(data);
  console.log("VERIFY USER:");
  console.log(userId);
  let url = SMS_VERIFY_POST_API();
  if (userId) url += `?u=${userId}`;
  return axios.post(url, data);
};

export const getSmsVerifyCode = (userId = null) => {
  let url = SMS_VERIFY_GET_API();
  if (userId) url += `?u=${userId}`;
  return axios.get(url);
};

// export const getCashBackAffiliate = (filters) => {
//   let url = CASH_BACK_AFFILIATE_GET_API();
//   url += serializeArray(filters);
//   return axios.get(url);
// };
export const getCashBackAffiliate = (userid, filters) => {
  let url = CASH_BACK_AFFILIATE_GET_API();
  url += `id=${userid}&from=${filters?.from}&to=${filters?.to}`;
  return axios.get(url);
};

export const getIB = (
  isAdmin,
  userid,
  filters,
  start = 0,
  limit = 10,
  query
) => {
  let url = "";
  if (isAdmin) {
    url = IB_USERS_GET_API();
    url += `from=${filters?.from}&to=${filters?.to}&start=${start}&limit=${limit}`;
  } else {
    url = IB_GET_API();
    url += `id=${userid}&from=${filters?.from}&to=${filters?.to}`;
  }
  if (query) {
    url += `&query=${query}`;
  }
  console.log("URL: ");
  console.log(url);
  return axios.get(url);
};

export const getTotalCommission = (filters) => {
  let url = "";
  url = TOTAL_COMMISSION_GET_API();
  url += `from=${filters?.from}&to=${filters?.to}`;
  console.log(url);
  return axios.get(url);
};

export const getPayoutsHistory = (filters) => {
  let url = PAYOUTS_HISTORY_GET_API();
  url += `from=${filters?.from}&to=${filters?.to}`;
  return axios.get(url);
};

export const getAccountTypes = () => {
  let url = ACCOUNTS_TYPE_GET_API();
  return axios.get(url);
};

export const profilePut = (id, data) => {
  const url = PROFILE_PUT_API(id);
  return axios.put(url, data);
};

export const updateUserStatus = (id, data) => {
  const url = UPDATE_USER_STATUS(id);
  return axios.put(url, data);
};

export const getAllSupporters = () => {
  const url = GET_ALL_SUPPORTERS();
  return axios.get(url);
};

export const assignSupporter = (id, data) => {
  const url = ASSIGN_SUPPORT_API(id);
  return axios.put(url, data);
};

export const usersGet = (limit = -1, start = 0, query) => {
  let url = USERS_GET_API(limit, start);
  if (query !== null) {
    url += `&query=${query}`;
  }
  return axios.get(url);
};

export const countUsersGet = (filter = {}) => {
  let filters = filter;
  if (typeof filter !== "string") {
    if (filters?.lng) {
      delete filters.lng;
    }
    if (filters?.subpath) {
      delete filters.subpath;
    }
    filters = serializeArray(filters);
  }
  const url = USERS_COUNT_GET_API(filters);
  return axios.get(url);
};

export const usersPost = (value) => {
  const url = USERS_POST_API();
  return axios.post(url, value);
};

export const uploadFile = (data) => {
  const url = UPLOAD_POST_API();
  const formData = new FormData();
  formData.append("files", data);
  const contentType = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return axios.post(url, formData, contentType);
};

export const commentsPost = (data) => {
  const url = CUSTOME_COMMENTS_POST();
  return axios.post(url, data);
};

export const usersSelectGet = (value) => {
  const url = GRAPHQL();
  return axios.post(url, {
    query: `{
      users(where: { last_name_containss: "${value || ""}" }) {
        id
        first_name
        last_name
      }
    }`,
  });
};

export const profileMe = (id = "me") => {
  const url = USER_GET_API(id);
  return axios.get(url);
};

export const notificationGet = () => {
  const url = NOTIFICATION_GET_API();
  return axios.get(url);
};

export const identityApproveCodePost = (data) => {
  const url = IDENTITY_APPROVE_CODE_POST_API();
  return axios.post(url, data);
};

export const securityPinGet = (data) => {
  const url = SECURITY_PIN_GET_API();
  return axios.get(url);
};

export const walletNumberPost = (data) => {
  const url = WALLET_NUMBER_POST_API();
  return axios.post(url, data);
};

export const walletTransferPost = (data) => {
  const url = WALLET_TRANSFER_POST_API();
  return axios.post(url, data);
};

export const sumOfTransactionsGet = (userid) => {
  let url = SUM_OF_TRANSACTION_GET_API(userid ? userid : "");
  const token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjMwNjcwNDc5LCJleHAiOjE2MzMyNjI0Nzl9.vqLPZM9onPswvJjo-__ZXQBNsEwTwh8KuHHXAFxbe_8";
  return axios.get(url, { headers: { Authorization: `Bearer ${token}` } });
};

export const activeUsersGet = (filters) => {
  let url = ACTIVE_USERS_GET_API();
  url += serializeArray(filters);
  return axios.get(url);
};

export const commissionCalculate = (filters) => {
  let url = COMMISSION_CALCULATE_GET_API();
  url += serializeArray(filters);
  return axios.get(url);
};

export const transactionsPost = (data) => {
  const url = TRANSACTIONS_POST_API();
  return axios.post(url, data);
};

export const transactionsApprove = (data) => {
  const url = TRANSACTIONS_APPROVE_POST_API();
  return axios.post(url, data);
};

export const affiliateCommissionRate = () => {
  const url = AFFILIATE_COMMISSION_GET_API();
  return axios.get(url);
};

export const financialStatistics = () => {
  const url = FINANCIAL_STATISTICS_GET_API();
  return axios.get(url);
};

export const fundingMethodSettingGet = () => {
  let url = FUNDING_METHOD_SETTING_GET_API();
  return axios.get(url);
};

export const paymentMethodsGet = (userId = null) => {
  let url = PAYMENT_METHOD_GET_API();
  if (userId) {
    url += `?user=${userId}`;
  }
  return axios.get(url);
};

export const newPaymentMethodPost = (data) => {
  const url = PAYMENT_METHOD_POST_API();
  return axios.post(url, data);
};

export const paymentMethodDelete = (id) => {
  const url = PAYMENT_METHOD_DELETE_API(id);
  return axios.delete(url);
};

export const paymentMethodUpdate = (id, data) => {
  const url = PAYMENT_METHOD_UPDATE_API(id);
  return axios.put(url, data);
};

export const systemPaymentMethodsGet = () => {
  const url = SYSTEM_PAYMENT_METHOD_GET_API();
  return axios.get(url);
};

export const syncUserAccounts = () => {
  const url = SYNC_USER_ACCOUNTS_GET_API();
  return axios.get(url);
};

export const accountsDeposit = (data) => {
  const url = ACCOUNT_DEPOSIT_POST_API();
  return axios.post(url, data);
};

export const getCalendar = (filters) => {
  let url = GET_CALENDAR_API();
  url += `from=${filters.from}&to=${filters.to}`;
  return axios.get(url);
};

export const postCalendar = (data) => {
  let url = POST_CALENDAR_API();
  return axios.post(url, data);
};

export const deleteCalendar = (id) => {
  let url = DELETE_CALENDAR_API(id);
  return axios.delete(url);
};

export const accountsWithdrawal = (data) => {
  const url = ACCOUNT_WITHDRAWAL_POST_API();
  return axios.post(url, data);
};

export const updatePhoneTrading = (data) => {
  const url = UPDATE_PHONE_TRADING_GET_API();
  return axios.put(url, data);
};

export const uploadUserAvatar = (data) => {
  const url = USER_AVATAR_API();
  var formData = new FormData();
  formData.append("files.avatar", data);
  formData.append("data", "{}");
  const contentType = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return axios.put(url, formData, contentType);
};

export const getAdvertising = () => {
  const url = ADVERTISING_MEDIA_GET_API();
  return axios.get(url);
};

export const chartsGet = (login, filters) => {
  let url = CHART_GET_API();
  url += `login=${login}&from=${filters.from}&to=${filters.to}`;
  return axios.get(url);
};

export const userSendDocuments = (data, userID = null) => {
  var formData = new FormData();
  data.forEach((item) => {
    // if(item.files.length > 1) {
    item.files.forEach((file) => {
      formData.append("files." + item.type, file);
    });
    // }
    // else {
    //   formData.append('files.' + item.type, item.files);
    // }
  });
  if (userID !== null) {
    formData.append("data", '{"user":"' + userID + '"}');
  } else {
    formData.append("data", "{}");
  }
  const url = USER_SEND_DOCUMENTS();
  const contentType = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return axios.post(url, formData, contentType);
};

export const userSendEmail = (data, status) => {
  let finalData = data;
  finalData.status = status;
  var formData = new FormData();
  if (data?.files?.fileList) {
    data.files.fileList.forEach((item) => {
      formData.append("files.file", item.originFileObj);
    });
  }
  delete finalData.files;
  formData.append("data", JSON.stringify(finalData));
  const url = USER_MAIL_API();
  const contentType = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  return axios.post(url, formData, contentType);
};

export const getSentMails = (type, id) => {
  let url = USER_GET_MAIL_API(type);
  url += `?createdBy=${id}`;
  return axios.get(url);
};

export const userGetMailInbox = (id) => {
  let url = USER_GET_MAIL_API(id);
  return axios.get(url);
};

export const getMailById = (id) => {
  let url = GET_MAIL_BY_ID_API();
  url += `?id=${id}`;
  return axios.get(url);
};

export const getMailLabels = () => {
  let url = GET_MAIL_LABELS_API();
  return axios.get(url);
};

export const getMailByLabel = (labelid) => {
  let url = GET_MAIL_LABELS_DATA_API(labelid);
  return axios.get(url);
};

export const mailUpdateMultiple = (ids) => {
  let url = MAIL_PUT_MULTIPLE();
  return axios.put(url, ids);
};

export const deleteMail = (ids) => {
  let url = MAIL_PUT_MULTIPLE();
  return axios.put(url, { deleted: true, ...ids });
};

export const getAccountData = (user) => {
  const url = GET_ACCOUNT_DATA(user);
  return axios.get(url);
};

export const getUserDocuments = (id) => {
  const url = USER_GET_DOCUMENTS(id);
  return axios.get(url);
};

export const getUserOpenPositions = (login) => {
  const url = GET_USER_OPEN_POSITIONS(login);
  return axios.get(url);
};

export const getUserDealsHistory = (login, filters) => {
  let url = GET_USER_DEALS_HISTORY(login);
  // url += serializeArray(filters);
  url += `from=${filters.from}&to=${filters.to}`;
  return axios.get(url);
};

export const verifyUserDocuments = (id, status) => {
  const url = USER_DOCUMENTS_ACTION(id);
  return axios.put(url, status);
};

export const declineUserDocuments = (id, data) => {
  const url = USER_DOCUMENTS_ACTION(id);
  return axios.put(url, data);
};

export const deleteUserAvatar = () => {
  const url = USER_AVATAR_API();
  return axios.delete(url);
};

export const setPhoneTrading = (data) => {
  const url = SET_PHONE_TRADING_POST_API();
  return axios.post(url, data);
};

export const getPhoneTrading = () => {
  const url = GET_PHONE_TRADING_GET_API();
  return axios.get(url);
};

export const getSetting = () => {
  const url = SETTING_GET_API();
  return axios.get(url);
};

export const enableTwoFactor = () => {
  const url = TWO_FACTOR_ENABLE_GET_API();
  return axios.get(url);
};

export const disableTwoFactor = () => {
  const url = TWO_FACTOR_DISABLE_GET_API();
  return axios.get(url);
};

export const verifyTwoFactor = (data) => {
  const url = TWO_FACTOR_VERIFY_POST_API();
  return axios.post(url, data);
};

export const loginTwoFactor = (data) => {
  const url = TWO_FACTOR_LOGIN_POST_API();
  return axios.post(url, data);
};

export const getCurrencyExchanges = () => {
  const url = EXCHNAGE_GET_API();
  return axios.get(url);
};

export const addUserNotes = (data) => {
  const url = ADD_USER_NOTES();
  return axios.post(url, data);
};

export const updateUserNotes = (userid, data) => {
  const url = UPDATE_USER_NOTES(userid);
  return axios.put(url, data);
};

export const getUserNotes = (id) => {
  const url = GET_USER_NOTES(id);
  return axios.get(url);
};

export const getUserStatusList = () => {
  const url = GET_USER_STATUS();
  return axios.get(url);
};

export const getFaqs = () => {
  const url = GET_FAQS();
  return axios.get(url);
};

export const walletsGet = (limit, offset, query, amountGt, amountLt) => {
  let url = WALLETS_GET_API();
  url += `limit=${limit}&offset=${offset}&query=${query}&wallet_gt=${amountGt}&wallet_lt=${amountLt}`;
  console.log(url);
  return axios.get(url);
};

export const getLeverage = (id) => {
  const url = GET_LEVERAGE_API(id);
  return axios.get(url);
};

export const updateAccountLeverage = (id, data) => {
  const url = UPDATE_USER_LEVERAGE_API(id);
  return axios.put(url, data);
};

export const updateAccountDeposit = (data) => {
  const url = UPDATE_USER_DEPOSIT_API();

  return axios.post(url, data);
};

export const resetAccountPassword = (data) => {
  const url = RESET_ACCOUNT_PASSWORD();
  return axios.put(url, data);
};

export const changeAccountPassword = (data) => {
  const url = CHANGE_ACCOUNT_PASSWORD();
  return axios.put(url, data);
};

export const getAccountSingle = (id) => {
  const url = ACCOUNT_GET_API(id);
  return axios.get(url);
};

export const confirmUserMail = (id, data) => {
  const url = CONFIRM_USER_MAIL(id);
  return axios.put(url, data);
};

export const confirmUserPhone = (id, data) => {
  const url = CONFIRM_USER_PHONE(id);
  return axios.put(url, data);
};

export const getActiveMembers = () => {
  const url = COUNT_USERS();
  return axios.get(url);
};

export const transactionsActionPut = (id, data) => {
  console.log(data);
  console.log(id);
  const url = TRANSACTIONS_PUT_API(id);
  return axios.put(url, data);
};

export const getCashBack = (filters, start, limit, query) => {
  let url = GET_CASHBACK_API();
  url += `from=${filters?.from}&to=${filters?.to}&start=${start}&limit=${limit}`;
  if (query !== null) {
    url += `&query=${query}`;
  }
  console.log(url);
  return axios.get(url);
};

export const getTotalCashback = (filters) => {
  let url = TOTAL_CASHBACK_API();
  url += `from=${filters?.from}&to=${filters?.to}`;
  return axios.get(url);
};

export const getWalletHistory = (filters, user = -1) => {
  console.log("SENT DATA:");
  console.log(filters);
  console.log(user);
  let url = WALLET_HISTORY_GET_API();
  url += `from=${filters?.from}&to=${filters?.to}&user=${user}`;
  return axios.get(url);
};

export const getTotalLots = (
  filters,
  account = -1,
  account_type = -1,
  user = -1
) => {
  let url = TOTAL_LOT_GET_API();
  url += `from=${filters?.from}&to=${filters?.to}&account=${account}&account_type=${account_type}&user=${user}`;
  return axios.get(url);
};

export const getAllCommissions = (
  filters,
  account = -1,
  account_type = -1,
  user = -1
) => {
  let url = ALL_COMMISSION_GET_API();
  url += `from=${filters?.from}&to=${filters?.to}&account=${account}&account_type=${account_type}&user=${user}`;
  return axios.get(url);
};

export const getPermissions = () => {
  const url = PERMISSIONS_GET_API();
  // HARDCODE
  const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjQxODEwMTY4LCJleHAiOjE2NDQ0MDIxNjh9.Zlz4DaGpUAoZrNUd-VYjhoSvDQh_HDSm7LjWbGnCCI8'
  // const token = localStorage.getItem(AUTH_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return axios.get(url, config);
};

export const getRoles = () => {
  const url = ROLES_GET_API()

  const token = localStorage.getItem(AUTH_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return axios.get(url, config);

}

export const createRoles = (data) => {
  const url = ROLES_POST_API()

  const token = localStorage.getItem(AUTH_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return axios.post(url, data, config);

}

export const updateRoles = (data) => {
  const url = ROLES_PUT_API(data.id)

  const token = localStorage.getItem(AUTH_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return axios.put(url, data, config);

}

// permissions
export const getRolePermissions = (id) => {
  const url = ROLE_PERMISSIONS_GET_API(id)

  const token = localStorage.getItem(AUTH_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return axios.get(url, config);

}

export const updatePermissions = (data) => {
  const url = PERMISSIONS_PUT_API()

  const token = localStorage.getItem(AUTH_TOKEN)
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  }
  return axios.put(url, { data }, config);

}
export const sendEmailManualVerificationToUser = (id) => {
  const url = EMAIL_MANUAL_SEND_VERIFICATION(id);
  return axios.put(url);
};

export const sendPhoneManualVerificationToUser = (id) => {
  const url = PHONE_MANUAL_SEND_VERIFICATION(id);
  return axios.put(url);
};

export const transactionsGetAll = (start = 0, limit = 0, filters = null) => {
  let url = TRANSACTIONS_GET_ALL_API();
  url += `start=${start}&limit=${limit}`;
  if (filters !== null) {
    url += `&from=${filters?.from}&to=${filters?.to}&type=${filters?.type}&status=${filters?.status}&account=${filters?.account}&amount_lt=${filters?.amount_lt}&amount_gt=${filters?.amount_gt}`;
  }
  console.log(url);
  return axios.get(url);
};

export const transactionsGet = (id, start = 0, limit = 0, filters = null) => {
  let url = "";
  if (id === "all") {
    url = TRANSACTIONS_GET_ALL_API();
    url += `start=${start}&limit=${limit}`;
    if (filters !== null) {
      url += `&from=${filters?.from}&to=${filters?.to}&type=${filters?.type}&status=${filters?.status}&account=${filters?.account}&amount_lt=${filters?.amount_lt}&amount_gt=${filters?.amount_gt}`;
    }
  } else {
    url = TRANSACTIONS_GET_API();
    url += `user=${id}&start=${start}&limit=${limit}`;
    if (filters !== null) {
      url += `&from=${filters?.from}&to=${filters?.to}&type=${filters?.type}&status=${filters?.status}&account=${filters?.account}&amount_lt=${filters?.amount_lt}&amount_gt=${filters?.amount_gt}`;
    }
    console.log(url);
  }
  return axios.get(url);
};
