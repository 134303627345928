import React, { useState } from 'react'
import { APP_NAME } from 'configs/AppConfig'
import { Modal } from 'antd'
import IntlMessage from 'components/util-components/IntlMessage'

export default function Footer() {
  const [termsModalIsOpen, setTermsModalIsOpen] = useState(false)
  const [policyModalIsOpen, setPolicyModalIsOpen] = useState(false)

  return (
    <footer className="footer">
      <span>
        {<IntlMessage id="footer.copyright" />}{' '}
        {`${new Date().getFullYear()}`}{' '}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span>{' '}
        {<IntlMessage id="footer.all_rights_reserved" />}
      </span>
      <Modal
        title={<IntlMessage id="footer.terms_conditions" />}
        footer={null}
        width="85%"
        style={{ maxWidth: '700px' }}
        visible={termsModalIsOpen}
        onCancel={() => {
          setTermsModalIsOpen(false)
        }}
      >
        <div>
          <h2>Who are we ?</h2>
          <p>
            NYX is a company, providing infrastructure and services for trading
            in financial markets at the global level. Our team with over nine
            years of experience in online trading and analysis of Forex and
            derivations, offers various account types to all individuals,
            institutional and affiliates clients with superior trading
            conditions and lightning-fast execution that perfectly suit their
            needs.
          </p>
          <h2>Why NYX?</h2>
          <p>
            We have built our business around being on our customers side.
            Therefore, we want you to trade profitably to stay more with us.
          </p>
          <h2>ABOUT NYX</h2>
          <p>
            At NYX, we will offer you the latest trading platform with
            infrastructure and professional services for trading in the global
            financial markets.
          </p>
        </div>
      </Modal>
      <Modal
        title={<IntlMessage id="footer.privacy_policy" />}
        footer={null}
        visible={policyModalIsOpen}
        width="85%"
        style={{ maxWidth: '700px' }}
        onCancel={() => {
          setPolicyModalIsOpen(false)
        }}
      >
        <div>
          <h2>Who are we ?</h2>
          <p>
            NYX is a company, providing infrastructure and services for trading
            in financial markets at the global level. Our team with over nine
            years of experience in online trading and analysis of Forex and
            derivations, offers various account types to all individuals,
            institutional and affiliates clients with superior trading
            conditions and lightning-fast execution that perfectly suit their
            needs.
          </p>
          <h2>Why NYX?</h2>
          <p>
            We have built our business around being on our customers side.
            Therefore, we want you to trade profitably to stay more with us.
          </p>
          <h2>ABOUT NYX</h2>
          <p>
            At NYX, we will offer you the latest trading platform with
            infrastructure and professional services for trading in the global
            financial markets.
          </p>
        </div>
      </Modal>

      <div>
        <span
          className="text-gray cursor-pointer"
          href="/#"
          onClick={(e) => {
            e.preventDefault()
            setTermsModalIsOpen(true)
          }}
        >
          {<IntlMessage id="footer.terms_conditions" />}
        </span>
        <span className="mx-2 text-muted"> | </span>
        <span
          className="text-gray cursor-pointer"
          onClick={(e) => {
            e.preventDefault()
            setPolicyModalIsOpen(true)
          }}
        >
          {<IntlMessage id="footer.privacy_policy" />}
        </span>
      </div>
    </footer>
  )
}
