import antdFaIR from 'antd/es/locale/fa_IR';
import faMsg from "../locales/fa_IR.json";

const FaLang = {
  antd: antdFaIR,
  locale: 'fa-IR',
  direction:'rtl',
  messages: {
    ...faMsg
  },
};
export default FaLang;
