// API URL FOR DEVELOPMENT MODE
const dev = {
	API_ENDPOINT_URL: 'https://api-dev.nyxbroker.com',
	CHAT_ENDPOINT_URL: 'https://chat.nyxbroker.com',
};
// API URL FOR PRODUCTION MODE
const prod = {
	API_ENDPOINT_URL: 'https://api-dev.nyxbroker.com',
	CHAT_ENDPOINT_URL: 'https://chat.nyxbroker.com'
};
// API URL FOR TEST MODE
const test = {
	API_ENDPOINT_URL: 'http://local-my.nyxbroker.com:1340'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
