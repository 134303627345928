import React, { useState } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";
import { onSwitchTheme, onHeaderNavColorChange } from "redux/actions/Theme";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdjust } from "@fortawesome/free-solid-svg-icons";

export const NavSwitchMode = ({
  currentTheme,
  onHeaderNavColorChange,
  onSwitchTheme,
}) => {
  const [switchMode, setSwitchMode] = useState(true);
  const { switcher, themes } = useThemeSwitcher();

  const toggleTheme = () => {
    onHeaderNavColorChange("");
    const changedTheme = switchMode ? "dark" : "light";
    onSwitchTheme(changedTheme);
    switcher({ theme: themes[changedTheme] });
  };
  return (
    <>
      <Menu mode="horizontal">
        <Menu.Item
          onClick={() => {
            setSwitchMode(switchMode ? false : true);
            toggleTheme();
          }}
        >
          <FontAwesomeIcon icon={faAdjust} className="nav-icon mr-0" />
        </Menu.Item>
      </Menu>
      {/* <ListOption 
					name="Dark Theme:"
					selector={
						<Switch checked={currentTheme === 'dark'} onChange={toggleTheme} />
					}
				/> */}
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const { currentTheme } = theme;
  return { currentTheme };
};

const mapDispatchToProps = {
  onHeaderNavColorChange,
  onSwitchTheme,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavSwitchMode);
