import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
// import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import {
  DashboardOutlined,
  AppstoreOutlined,
  AntDesignOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BarChartOutlined,
  SolutionOutlined,
  IdcardOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  UnorderedListOutlined,
  ApiOutlined,
  ClusterOutlined,
  DollarOutlined,
  BorderlessTableOutlined,
  UserSwitchOutlined,
  GiftOutlined,
  CreditCardOutlined,
  WalletOutlined,
  FileDoneOutlined,
  RadarChartOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { appContext } from "services/Context";
import moment from "moment";
import {
  AUTH_TOKEN,
  REGISTER_TOKEN,
  CAPTCHA_TOKEN,
} from "redux/constants/Auth";
import { useIntl } from "react-intl";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    navigationConfig,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };
  const [navigationDisplay, setNavigationDisplay] = useState("block");
  const { user } = useContext(appContext);
  var startLogin = moment(user?.last_online).toDate();
  var timeNow = new Date();
  if (timeNow.getHours() - startLogin.getHours() >= 12) {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(REGISTER_TOKEN);
    localStorage.removeItem(CAPTCHA_TOKEN);
    window.location.reload();
  }

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            title={
              String(setLocale(localization, menu.title)).toUpperCase() ===
                "SETTINGS" &&
              String(user?.role) !== "manager" &&
              String(user?.role) !== "admin"
                ? ""
                : setLocale(localization, menu.title)
            }
          >
            {menu.submenu
              .filter(
                (elm) =>
                  String(elm.allowed).includes(String(user?.role)) ||
                  elm.allowed === null
              )
              .map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={setLocale(localization, subMenuFirst.title)}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>
                          {setLocale(localization, subMenuSecond.title)}
                        </span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? (
                      <Icon type={subMenuFirst.icon} />
                    ) : null}
                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                    <Link
                      onClick={() => closeMobileNav()}
                      to={subMenuFirst.path}
                    />
                  </Menu.Item>
                )
              )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization, navigationConfig } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  const intl = useIntl()

  const navigationConfig = [
    {
      key: "dashboards",
      path: `${APP_PREFIX_PATH}/dashboards`,
      title: intl.formatMessage({id: 'navigation_sidebar.dashboard'}), 
      icon: BarChartOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: "dashboards",
          path: `${APP_PREFIX_PATH}/dashboards/default`,
          title: "navigation_sidebar.dashboard",
          icon: BarChartOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
        {
          key: "dashboards-analytic",
          path: `${APP_PREFIX_PATH}/dashboards/analytic`,
          title: "navigation_sidebar.analytic",
          icon: RadarChartOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: "admin,manager",
        },
      ],
    },
    {
      key: "personalinfo",
      path: `${APP_PREFIX_PATH}/dashboards`,
      title: "navigation_sidebar.client_info",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: "dashboards-users",
          path: `${APP_PREFIX_PATH}/pages/user-list`,
          title: "navigation_sidebar.user_list",
          icon: SolutionOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: "support,admin,manager",
        },
        {
          key: "extra-pages-setting",
          path: `${APP_PREFIX_PATH}/pages/setting`,
          title: "navigation_sidebar.profile",
          icon: IdcardOutlined,
          breadcrumb: true,
          submenu: [],
          allowed: null,
        },
        {
          key: "extra-pages-setting-all-documents",
          path: `${APP_PREFIX_PATH}/pages/documents`,
          title: "navigation_sidebar.documents",
          icon: FileDoneOutlined,
          breadcrumb: true,
          submenu: [],
          allowed: "support,admin,manager",
        },
        {
          key: "extra-pages-setting-all-billing",
          path: `${APP_PREFIX_PATH}/pages/fundingmethods`,
          title: "navigation_sidebar.funding_methods",
          icon: CreditCardOutlined,
          breadcrumb: true,
          submenu: [],
          allowed: "support,admin,manager",
        },
        {
          key: "dashboards-accounts-history",
          path: `${APP_PREFIX_PATH}/pages/trading-accounts`,
          title: "navigation_sidebar.accounts",
          icon: AppstoreOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
      ],
    },
    {
      key: "financial",
      path: `${APP_PREFIX_PATH}/dashboards`,
      title: "navigation_sidebar.financial",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: "dashboards-billing",
          path: `${APP_PREFIX_PATH}/pages/billing`,
          title: "navigation_sidebar.billing",
          icon: CreditCardOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
        {
          key: "dashboards-financial-wallets",
          path: `${APP_PREFIX_PATH}/pages/wallets`,
          title: "navigation_sidebar.wallets",
          icon: WalletOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: "support,admin,manager",
        },
        // {
        //       key: 'dashboards-financial-currencyExchange',
        //       path: `${APP_PREFIX_PATH}/dashboards/default`,
        //       title: 'sidenav.dashboard.financial.currencyExchange',
        //       icon: DashboardOutlined,
        //       breadcrumb: false,
        //       submenu: []
        // },
        {
          key: "dashboards-partnership",
          path: `${APP_PREFIX_PATH}/pages/partnership`,
          title: "navigation_sidebar.partnership",
          icon: ClusterOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
      ],
    },
    {
      key: "apps",
      path: `${APP_PREFIX_PATH}/apps`,
      title: "navigation_sidebar.support",
      icon: AppstoreOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: "apps-mail",
          path: `${APP_PREFIX_PATH}/apps/mail/inbox`,
          title: "navigation_sidebar.mail",
          icon: MailOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: "admin,manager",
        },
        {
          key: "apps-chat",
          path: `${APP_PREFIX_PATH}/apps/chat`,
          title: "navigation_sidebar.chat",
          icon: MessageOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
        {
          key: "dashboards-notes",
          path: `${APP_PREFIX_PATH}/pages/notes`,
          title: "navigation_sidebar.notes",
          icon: EditOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: "support,admin,manager",
        },
        {
          key: "apps-calendar",
          path: `${APP_PREFIX_PATH}/apps/calendar`,
          title: "navigation_sidebar.calendar",
          icon: CalendarOutlined,
          breadcrumb: true,
          submenu: [],
          allowed: "support,admin,manager",
        },
        {
          key: "setting-Faq",
          path: `${APP_PREFIX_PATH}/pages/faq`,
          title: "navigation_sidebar.faq",
          icon: QuestionCircleOutlined,
          breadcrumb: false,
          submenu: [],
          allowed: null,
        },
      ],
    },
    {
      key: "settings",
      path: `${APP_PREFIX_PATH}/settings`,
      title: "navigation_sidebar.settings",
      icon: AntDesignOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: "Faq",
          // path: `${APP_PREFIX_PATH}/pages/faq`,
          title: "navigation_sidebar.faq",
          icon: QuestionCircleOutlined,
          breadcrumb: false,
          allowed: "admin,manager",
          submenu: [
            {
              key: "setting-Faq-Categories",
              path: `${APP_PREFIX_PATH}/pages/faqCategories`,
              title: "navigation_sidebar.categories",
              icon: "",
              breadcrumb: false,
              submenu: [],
            },
            {
              key: "setting-Faq-FAQs",
              path: `${APP_PREFIX_PATH}/pages/faqs`,
              title: "navigation_sidebar.faq",
              icon: "",
              breadcrumb: false,
              submenu: [],
            },
          ],
        },
        {
          key: "Trading Accounts",
          path: `${APP_PREFIX_PATH}/pages/tradingAccounts`,
          title: "navigation_sidebar.trading_accounts",
          icon: AppstoreOutlined,
          breadcrumb: false,
          allowed: "manager",
          submenu: [
            {
              key: "setting-AccountGroups",
              path: `${APP_PREFIX_PATH}/pages/accountGroups`,
              title: "navigation_sidebar.account_groups",
              icon: ApiOutlined,
              breadcrumb: false,
              submenu: [],
            },

            {
              key: "setting-AccountTypes",
              path: `${APP_PREFIX_PATH}/pages/accountTypes`,
              title: "navigation_sidebar.account_types",
              icon: UnorderedListOutlined,
              breadcrumb: false,
              submenu: [],
            },
          ],
        },
        {
          key: "Manage Roles",
          path: `${APP_PREFIX_PATH}/pages/manageRoles`,
          title: "Mange Roles",
          icon: SolutionOutlined,
          breadcrumb: false,
          allowed: "manager",
          submenu: [],
        },
        {
          key: "Partnership",
          path: `${APP_PREFIX_PATH}/pages/partnership`,
          title: "navigation_sidebar.partnership",
          icon: ClusterOutlined,
          breadcrumb: false,
          allowed: "admin,manager",
          submenu: [
            {
              key: "setting-IBCommissionRates",
              path: `${APP_PREFIX_PATH}/pages/IBCommissionRates`,
              title: "navigation_sidebar.IB_commision_rate",
              icon: DollarOutlined,
              breadcrumb: false,
              submenu: [],
            },
            {
              key: "setting-SymbolCommissionRates",
              path: `${APP_PREFIX_PATH}/pages/SymbolCommissionRates`,
              title: "navigation_sidebar.symbol_commision_rate",
              icon: BorderlessTableOutlined,
              breadcrumb: false,
              submenu: [],
            },
            {
              key: "setting-IBlevels",
              path: `${APP_PREFIX_PATH}/pages/IBLevels`,
              title: "navigation_sidebar.IB_levels",
              icon: UserSwitchOutlined,
              breadcrumb: false,
              submenu: [],
            },
            {
              key: "setting-CashbackRates",
              path: `${APP_PREFIX_PATH}/pages/CashbackRates`,
              title: "navigation_sidebar.cashback_rates",
              icon: GiftOutlined,
              breadcrumb: false,
              submenu: [],
            },
          ],
        },
        {
          key: "Financial",
          path: `${APP_PREFIX_PATH}/pages/Financial`,
          title: "navigation_sidebar.financial",
          icon: AppstoreOutlined,
          breadcrumb: false,
          allowed: "manager",
          submenu: [],
        },
      ],
    },
  ];
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} navigationConfig={navigationConfig} />
  ) : (
    <TopNavContent {...props} navigationConfig={navigationConfig} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
