import React, { Component } from "react";
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { API_BASE_URL, THEME_CONFIG } from './configs/AppConfig';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { PUBLIC_RECAPTCHA_KEY } from 'configs/AppConfig';
import { AppProvider } from 'services/Context';
import { AUTH_TOKEN } from 'redux/constants/Auth';
import { PAYMENT_METHOD_GET_API, USER_GET_API } from 'constants/ApiConstant';
import 'assets/css/antdCustom.css';
import permission from "utils/permission";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

class App extends Component {
  constructor () {
    super();
    this.state = {
      token: localStorage.getItem(AUTH_TOKEN),
      user: [],
      userID: 0,
      paymentMethods: [],
      role: null
    };
  }
  async componentDidMount() {
    if (this.state.token) {
      const userProfile = await fetch(USER_GET_API('me'), { method: 'get', headers: new Headers({ Authorization: `Bearer ${this.state.token}`, }), });
      const userProfileJson = await userProfile.json();
      const userPayment = await fetch(PAYMENT_METHOD_GET_API(), { method: 'get', headers: new Headers({ Authorization: `Bearer ${this.state.token}`, }), });
      const userPaymentJson = await userPayment.json();
      this.setState({ user: userProfileJson, userID: userProfileJson?.id, paymentMethods: userPaymentJson });
    }
  }
  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <AppProvider
            authenticated={localStorage.getItem(AUTH_TOKEN) ? true : false}
            token={localStorage.getItem(AUTH_TOKEN)}
          >
            <GoogleReCaptchaProvider
              useRecaptchaNet
              reCaptchaKey={PUBLIC_RECAPTCHA_KEY}
              scriptProps={{ async: true, defer: true, appendTo: 'body' }}
            >
              <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
                <Router>
                  <Switch>
                    <Route path="/" component={Views} />
                  </Switch>
                </Router>
              </ThemeSwitcherProvider>
            </GoogleReCaptchaProvider>
          </AppProvider>
        </Provider>
      </div>
    );
  }
}

App.getInitialProps = async () => {
  try {
    await permission.setPermissions()
  } catch (error) { }
}

export default App;
